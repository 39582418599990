import { DEFAULT_DIALOG_CONFIG, DialogConfig } from '@angular/cdk/dialog';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

/** Provides locale data for e.g. CurrencyPipe/DatePipe */
import '@angular/common/locales/global/de';
import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  ENVIRONMENT_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
  inject,
  isDevMode,
} from '@angular/core';
import {
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MatLuxonDateAdapterOptions,
  provideLuxonDateAdapter,
} from '@angular/material-luxon-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  IsActiveMatchOptions,
  PreloadAllModules,
  Router,
  RouterModule,
  provideRouter,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
  withViewTransitions,
} from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { provideHotToastConfig } from '@ngxpert/hot-toast';
import * as Sentry from '@sentry/angular';

import { AppCoreMaintenanceComponent } from '@husky/app-core-maintenance';
import {
  HUSKY_DRAWER_DEFAULT_OPTIONS,
  HuskyDrawerConfig,
  HuskyDrawerModule,
} from '@husky/app-shared-ui-drawer';
import { GoogleMapsApiKey } from '@husky/app-shared-ui-map';
import { AppSharedUtilAbilityModule } from '@husky/app-shared-util-ability';
import { AppSharedUtilAuthInterceptor } from '@husky/app-shared-util-auth';
import { AppCoreLayoutModule } from '@husky/app/core/layout';
import { AppSharedDataAccessModule } from '@husky/app/shared/data-access';
import { SplashScreenService } from '@husky/app/shared/ui-loading';
import { AppSharedUtilOperatorValidationModule } from '@husky/app/shared/util-validation';

import { CurrencyFormatPipe } from '../../../../libs/app/shared/ui-currency/src/lib/currencyFormat/currency-format.pipe';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppCoreLayoutModule,
    RouterModule,
    AppSharedDataAccessModule,
    AppSharedUtilOperatorValidationModule,
    CurrencyFormatPipe,
    AppSharedUtilAbilityModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    /** This is here for precautionary reasons, it should be removed if not needed */
    HuskyDrawerModule,
    AppCoreMaintenanceComponent,
  ],
  providers: [
    provideRouter(
      appRoutes,
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
      }),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
      }),
      withPreloading(PreloadAllModules),
      withViewTransitions({
        skipInitialTransition: true,
        onViewTransitionCreated: ({ transition }) => {
          const router = inject(Router);
          const targetUrl = router.getCurrentNavigation()!.finalUrl!;
          // Skip the transition if the only thing
          // changing is the fragment and queryParams
          const config: IsActiveMatchOptions = {
            paths: 'exact',
            matrixParams: 'exact',
            fragment: 'ignored',
            queryParams: 'ignored',
          };
          if (router.isActive(targetUrl, config)) {
            transition.skipTransition();
          }
        },
      }),
    ),
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppSharedUtilAuthInterceptor,
      multi: true,
    },
    {
      provide: DEFAULT_DIALOG_CONFIG,
      useValue: {
        hasBackdrop: true,
        closeOnNavigation: true,
        // TODO: If this is enabled, it also applies to drawer, potentially implement the same as drawer for dialog
        //panelClass: 'husky-dialog',
        backdropClass: 'cdk-overlay-dark-backdrop',
      } satisfies DialogConfig,
    },
    {
      provide: HUSKY_DRAWER_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        closeOnNavigation: true,
        height: '100vh',
        width: '540px',
        position: {
          top: '0px',
          right: '0px',
        },
      } satisfies HuskyDrawerConfig,
    },
    provideHotToastConfig({
      position: 'bottom-center',
      stacking: 'depth',
    }),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false, // Enable this to show a dialog when an error occurs
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(SplashScreenService),
      multi: true,
    },
    {
      provide: GoogleMapsApiKey,
      useValue: process.env['HUSKY_APP_GOOGLE_MAPS_API_KEY'] ?? '',
    },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: {
        firstDayOfWeek: 1,
        useUtc: false,
        defaultOutputCalendar: 'iso8601',
      } satisfies MatLuxonDateAdapterOptions,
    },
    provideLuxonDateAdapter(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
