import { Platform } from '@angular/cdk/platform';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { Meta } from '@angular/platform-browser';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Settings } from 'luxon';

import { AppSharedUtilAbilityService } from '@husky/app-shared-util-ability';
import { AppSharedUtilAuthenticatedUserService } from '@husky/app-shared-util-auth';

import { AppUpdateService } from './app-update.service';

@Component({
  selector: 'husky-root',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private readonly iconRegistry: MatIconRegistry,
    private readonly ngSelectConfig: NgSelectConfig,
    private readonly appUpdateService: AppUpdateService,
    private readonly authenticatedUserService: AppSharedUtilAuthenticatedUserService,
    private readonly abilityService: AppSharedUtilAbilityService,
    private readonly platform: Platform,
    private readonly meta: Meta,
  ) {}

  ngOnInit(): void {
    this.iconRegistry.setDefaultFontSetClass('material-symbols-rounded');
    Settings.defaultLocale = 'de-DE';

    this.ngSelectConfig.appendTo = 'body';

    this.appUpdateService.listen();
    this.authenticatedUserService.startPollingAuthenticatedUser();

    /** Fix iOS viewport zoom on input focus */
    /** https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone */
    /** TODO: Disabled temporarily, as we increased font-size to 16px for inputs and zoom on iOS is disabled when this is set */
    // if (this.platform.IOS) {
    //   this.meta.updateTag(
    //     {
    //       content: 'width=device-width, initial-scale=1, maximum-scale=1',
    //     },
    //     'name="viewport"',
    //   );
    // }
  }
}
