<div
  class="mt-1 border-y border-transparent"
  [ngClass]="{
    'border-y-slate-200 bg-slate-100': expanded(),
  }">
  <div
    class="flex cursor-pointer select-none items-center gap-2 border border-transparent py-1.5 transition ease-in-out"
    [ngClass]="{
      'mx-2 rounded-lg px-2 hover:border-slate-300 hover:bg-slate-600/10':
        !expanded(),
      'px-4': expanded(),
      'font-medium': expanded() || (hasActiveChild$ | async),
      '!border-slate-300 bg-slate-600/10':
        !expanded() && (hasActiveChild$ | async),
    }"
    (click)="toggleExpanded()">
    <mat-icon
      class="flex-none text-slate-500"
      [class.!text-primary-600]="expanded() || (hasActiveChild$ | async)"
      >{{ icon() }}
    </mat-icon>
    <span class="flex-1 truncate">{{ label() }}</span>
    <mat-icon class="flex-none text-slate-500" [class.rotate-90]="expanded()"
      >chevron_right
    </mat-icon>
  </div>

  <div class="sidenav-item-group__children" *ngIf="expanded()">
    <ng-content></ng-content>
  </div>
</div>
