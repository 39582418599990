import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChildren,
  EventEmitter,
  QueryList,
  computed,
  contentChildren,
  input,
  signal,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { RouterLinkActive } from '@angular/router';
import {
  EMPTY,
  from,
  map,
  merge,
  pairwise,
  scan,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { filter } from 'rxjs/operators';

import { filterNil } from '@husky/app/shared/util-operator';

import { SidenavItemComponent } from '../sidenav-item/sidenav-item.component';

@Component({
  selector: 'husky-sidenav-item-group',
  standalone: true,
  imports: [CommonModule, RouterLinkActive, MatIcon],
  templateUrl: './sidenav-item-group.component.html',
  styleUrl: './sidenav-item-group.component.scss',
})
export class SidenavItemGroupComponent {
  label = input.required<string>();
  icon = input.required<string>();

  expanded = signal<boolean>(false);

  readonly childItems = contentChildren(SidenavItemComponent, {
    descendants: true,
  });

  readonly hasActiveChild$ = toObservable(this.childItems).pipe(
    switchMap((items) =>
      merge(
        ...items
          .filter((item) => !!item.routerLinkActive?.isActiveChange)
          .map((item) =>
            item.routerLinkActive!.isActiveChange.pipe(
              map((isActive) => ({ id: item.path(), isActive })),
            ),
          ),
      ),
    ),
    scan((states, change) => {
      // Update our map of states with the new change
      states.set(change.id, change.isActive);
      return states;
    }, new Map<string, boolean>()),
    map((states) => {
      // If any state is true, return true
      return Array.from(states.values()).some((isActive) => isActive);
    }),
    // Start with false until we receive states
    startWith(false),
    pairwise(), // Emits pairs of [previous, current]
    tap(([prev, curr]) => {
      // If previously none were active, but now at least one is active
      if (!prev && curr) {
        // First activation
        this.expanded.set(true);
      }
    }),
    map(([_, curr]) => curr), // Return just the current value
  );

  toggleExpanded() {
    this.expanded.set(!this.expanded());
  }
}
